<template>
  <Banner bg-image="gray-bg.jpg" talk-img="risk-factor.png">
    <template v-slot:title>Suicide risk factors</template>
    <template v-slot:subtitle
      >People from all walks of life are impacted by suicide. There is no
      “typical” suicidal person, there are no “typical” suicidal thoughts and
      ideations. Most often, young people experience suicidal thoughts when they
      have lost hope and feel helpless; they want their pain to end, and they
      may see no other way out.
    </template>
    <template v-slot:description>
      Let’s explore some of the common risk factors for suicide.</template
    >
  </Banner>
  <!-- RISK FACTORS  -->
  <section class="pt-10 gradient-white md:pt-14">
    <div class="max-w-6xl px-4 mx-auto sm:px-6">
      <div
        class="mb-6 text-2xl font-bold text-center call-text md:text-3xl text-blue"
      >
        Common risk factors for youth suicide
      </div>
      <p class="max-w-3xl mx-auto text-xl text-center">
        While there is no “typical” suicidal person, there are some common
        stressors that can make young people feel like life isn’t worth living,
        especially if they don’t have coping strategies in place.
      </p>
      <div class="mt-8 faq-block suicide-faq">
        <div class="grid grid-cols-1 gap-3 md:grid-cols-2 lg:grid-cols-3 tabs">
          <div class="col-1">
            <div class="mb-3 faq-tab">
              <input type="checkbox" id="rd1" name="rd" />
              <label class="tab-label" for="rd1">Relationships</label>
              <div class="faq-tab-content">
                <p>
                  Your friend might feel disconnected from family or other close
                  friends. This could be due to a conflict or because they’ve
                  become a victim of bullying at school or online.
                </p>
              </div>
            </div>
            <div class="mb-3 faq-tab">
              <input type="checkbox" id="rd2" name="rd" />
              <label class="tab-label" for="rd2">LGBTQ2S+ pressures</label>
              <div class="faq-tab-content">
                <p>
                  Young people who are Two-Spirit, gay, lesbian, bisexual or
                  transgender may feel pressured to hide their sexual
                  orientation or gender identity from others and may worry about
                  being rejected by their family or about being “outed.”
                </p>
              </div>
            </div>
            <div class="mb-3 faq-tab">
              <input type="checkbox" id="rd3" name="rd" />
              <label class="tab-label" for="rd3"
                >Disconnection from culture
              </label>
              <div class="faq-tab-content">
                <p>
                  Studies have shown that children & youth with no one to help
                  them understand their culture or those disconnected from their
                  communities and cultural practices were more likely to have
                  suicide ideation.
                </p>
              </div>
            </div>
          </div>
          <div class="col-1">
            <div class="mb-3 faq-tab">
              <input type="checkbox" id="rd4" name="rd" />
              <label class="tab-label" for="rd4">School</label>
              <div class="faq-tab-content">
                <p>
                  Problems with schoolwork, peers, peer pressure, bullying or
                  teachers can make day-to-day feel unbearable.
                </p>
              </div>
            </div>
            <div class="mb-3 faq-tab">
              <input type="checkbox" id="rd5" name="rd" />
              <label class="tab-label" for="rd5"
                >Impact of intergenerational trauma</label
              >
              <div class="faq-tab-content">
                <p>
                  The historical events of colonialism, which continue to affect
                  Indigenous peoples today, cannot be overstated.
                </p>
              </div>
            </div>
            <div class="mb-3 faq-tab">
              <input type="checkbox" id="rd6" name="rd" />
              <label class="tab-label" for="rd6"
                >Recent severe stressors
              </label>
              <div class="faq-tab-content">
                <p>
                  Stressors may include trauma, difficulties in dealing with
                  sexual orientation, unplanned pregnancy or other significant
                  real or impending loss.
                </p>
              </div>
            </div>
          </div>
          <div class="col-1">
            <div class="mb-3 faq-tab">
              <input type="checkbox" id="rd7" name="rd" />
              <label class="tab-label" for="rd7">Abuse</label>
              <div class="faq-tab-content">
                <p>
                  Another common secret is physical or emotional abuse or sexual
                  abuse which youth often hide out of fear or shame.
                </p>
              </div>
            </div>
            <div class="mb-3 faq-tab">
              <input type="checkbox" id="rd8" name="rd" />
              <label class="tab-label" for="rd8">Mental health problems </label>
              <div class="faq-tab-content">
                <p>
                  Depression, anxiety, eating disorders, low self-esteem or
                  addictions can cause significant stress. Your friend may
                  experience these problems directly or through guardians or
                  other family members that are experiencing them.
                </p>
              </div>
            </div>
            <div class="mb-3 faq-tab">
              <input type="checkbox" id="rd9" name="rd" />
              <label class="tab-label" for="rd9"
                >Family loss or instability
              </label>
              <div class="faq-tab-content">
                <p>
                  Dealing with family loss, a parent or guardian’s recent
                  separation or divorce, instability or significant family
                  conflict can feel like too much to overcome.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <FooterCard
    img="help-for-someone-else-braid.png"
    text="Explore warning signs"
    name="SuicideWarningSigns"
  >
    <template v-slot:header
      >Do you recognize risk factors? Next, learn about the warning signs of
      suicide.
    </template>
    <template v-slot:content>
      If someone is at risk. There are certain behaviors you can look for that
      may be warning signs of suicide.
    </template>
  </FooterCard>
</template>

<script>
import Banner from "@/components/Banner.vue";
import FooterCard from "@/components/FooterCard.vue";
export default {
  name: "SuicideRiskFactors",
  components: {
    Banner,
    FooterCard,
  },
};
</script>

<style scoped>
.gradient-white {
  background: linear-gradient(to bottom, #f8f8f8, #fff);
}
.faq-block .faq-tab {
  width: 100%;
  color: white;
  overflow: hidden;
}
.faq-block .tab-label {
  display: flex;
  justify-content: space-between;
  padding: 1em;
  background: rgba(60, 94, 170, 0.06);
  color: #3c5eaa;
  cursor: pointer;
  font-size: 20px;
}
.faq-block .tab-label::after {
  content: "";
  background-image: url(../../assets/images/arrow.svg);
  width: 1em;
  text-align: center;
  transition: all 0.35s;
  background-repeat: no-repeat;
  background-position: center;
}
.faq-block .faq-tab-content {
  max-height: 0;
  padding: 0 1em;
  color: #2c3e50;
  background: white;
  transition: all 0.35s;
}
.faq-block .faq-tab input {
  position: absolute;
  opacity: 0;
  z-index: -1;
}
.faq-block input:checked + .tab-label {
  background: #3c5eaa;
  color: #fff;
}
.faq-block input:checked + .tab-label::after {
  background-image: url(../../assets/images/arrow-up-white.svg);
}
.faq-block input:checked ~ .faq-tab-content {
  max-height: 100vh;
  padding: 1em;
}
.faq-tab {
  border: 5px solid #3c5eaa;
  border-radius: 10px;
}
.tab-label {
  background: #f8f8f8;
  font-weight: bold;
  height: 135px;
}
input:checked + .tab-label {
  background: #f8f8f8;
  color: #3c5eaa;
  height: auto;
}
.tab-label::after {
  background-position: -2px 10px;
  background-size: 1.8rem;
  width: 1.5rem;
}
input:checked + .tab-label::after {
  background-image: url(../../assets/images/arrow-up.svg);
}
.faq-tab-content {
  background: #f8f8f8;
  line-height: 30px;
}
</style>
